<template>
  <div class="bgStyle">
    <a-page-header title="执行任务" :ghost="true" class="page-header">
      <template slot="extra">
        <a-button key="1" type="primary" @click="toBack">
          返回
        </a-button>
      </template>
    </a-page-header>
    <Content :taskId="getTaskId" />
  </div>
</template>

<script>
import Content from "./components/TaskDetailContent.vue";
export default {
  name: "TaskDetail",
  components: {
    Content,
  },
  props: {
    taskId: { type: String, default: () => "" },
  },
  methods: {
    toBack() {
      let projectId =  this.$route.params.projectId || '';
      if(projectId) {
        this.$router.push("/projectManage/edit/" + projectId + '/1');
      } else {
        this.$router.go(-1);
      }      
    }
  },
  computed: {
    getTaskId() {
      // 兼容从路由传值和父子组件传值
      return this.taskId || this.$route.params.id;
    },
  },
};
</script>
<style lang="scss" scoped>
.bgStyle {
  height: calc(100% - 20px);
  background: transparent !important;
  .page-header {
    background: #fff;
    margin-bottom: 10px;
  }
}
</style>
